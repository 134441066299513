var render = function render(){var _vm=this,_c=_vm._self._c;return _c('g',[_vm._l((_vm.points),function(coordinate,index){return _c('circle',{key:index,class:`drag${coordinate.pk}`,attrs:{"id":`g${index}`,"stroke":coordinate.color,"fill":coordinate.color,"cx":_vm.btnRenderSide == 1 ? _vm.width - (coordinate.x * 60) * 1.5 * _vm.svgScale * (_vm.sliderScale != 0 ? 1 + (_vm.sliderScale * 0.10) : 1) : (coordinate.x * 60 + _vm.x_offset) * 1.5 * _vm.svgScale * (_vm.sliderScale != 0 ? 1 + (_vm.sliderScale * 0.10) : 1),"cy":_vm.btnRenderSide == 2 ? (coordinate.y * -200 + (_vm.y_offset * 2)) * 0.4 * (_vm.sliderScale != 0 ? 1 + (_vm.sliderScale * 0.10) : 1) : _vm.height - (coordinate.y * -200 + _vm.y_offset) * 0.4 * (_vm.sliderScale != 0 ? 1 + (_vm.sliderScale * 0.10) : 1),"r":100 * _vm.svgScale * (_vm.sliderScale != 0 ? 1 + (_vm.sliderScale * 0.10) : 1)},on:{"mouseover":function($event){_vm.showTooltip({
        x:
          (coordinate.x * 40 + _vm.x_offset) * 1.5 * _vm.svgScale * (_vm.sliderScale != 0 ? 1 + (_vm.sliderScale * 0.10) : 1),
        y:
          (_vm.height * 0.10 * _vm.svgScale) - ((coordinate.y * 200 + (_vm.y_offset)) * 0.4),
        
        text: `Name: ${coordinate.name} [x:${coordinate.x} y:${coordinate.y}]`,
      })},"mouseout":_vm.hideTooltip}})}),_c('text',{ref:"gtooltip",attrs:{"transform":"scale(1,-1)","font-size":"300","font-weight":"bold"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }